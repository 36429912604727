@import '../../styles/variables';

.regionContainer {
  min-height: 100%;
  .regionsList {
    padding: 1.5rem 0 1.5rem;
    .regionWrapper {
      padding-top: 0.781rem;
      padding-bottom: 0.781rem;
      max-width: 700px;
      .w-auto {
        width: auto !important;
      }
    }
  }
}

.sideMenu {
  width: 240px;
  background-color: $white;
  border-radius: 0.75rem;
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 1rem;
  label {
    margin-bottom: 0.5rem;
    color: $field-label-color;
    line-height: 1.25rem;
    padding-left: 1px;
  }
  input {
    border: 0.5px solid $field-border-color;
    border-radius: 0.187rem;
    padding: 0.625rem 0.75rem 0.75rem;
    width: 100%;
    height: 2.5rem;
    color: $field-text-color;
    &:hover {
      border-color: $field-focus-border-color;
    }
    &:focus {
      box-shadow: inset 0px 4px 8px $field-focus-box-shadow-color;
      border-color: $field-focus-border-color;
      outline: none;
    }
    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: $field-placeholder-color;
    }
    &::-moz-placeholder {
      /* Firefox 19+ */
      color: $field-placeholder-color;
    }
    &:-ms-input-placeholder {
      /* IE 10+ */
      color: $field-placeholder-color;
    }
    &:-moz-placeholder {
      /* Firefox 18- */
      color: $field-placeholder-color;
    }
  }
}

.customSidemenu {
  .header {
    padding-left: 0 !important;
  }
}
.disable {
  pointer-events: none;
  opacity: 0.4;
}

.sideMenuHeader {
  border-bottom: 1px solid $side-menu-border;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
}

.iconHover {
  background-color: $white;
  border: 1px solid var(--primary-error-color);
  border-radius: 50%;
  cursor: pointer;
  overflow: hidden;
}

.errorText {
  color: var(--primary-error-color);
}

.alertItems {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.alertModalIcon {
  height: 60px;
}

.alertModalText {
  text-align: center;
  font-size: large;
}

.closeButton {
  width: 100px;
  border: 5px solid #2514BE;
  border-radius: 5px;
  color: #fff;
  align-self: center;
  background-color: #2514BE;
}

.resetIcon {
  height: 1.5rem;
  width: 1.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  &:hover,
  &:active {
    @extend .iconHover;
    border: 1px solid var(--secondary-btn-border-color);
  }
  &:active {
    background-color: var(--secondary-btn-border-color);
    img {
      filter: brightness(0) invert(1);
    }
  }
}

.quickControls {
  display: flex;
  justify-content: space-evenly;
  button {
    border: 0 none;
    outline: none;
    line-height: 1rem;
    font-size: 1.25rem;
  }
  > div {
    width: 6.5rem;
    padding: 0;
  }
}

.navbarDropdown {
  flex-direction: column;
  width: 100%;
  height: 257px;
  overflow-y: scroll;
  transform: none !important;
  top: 36px !important;
  font-family: sans-serif;
}

.report_card_text {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 22px;
  align-items: center;
  text-align: center;
  color: #2514BE;  
  word-spacing: 9999px
}

